import React, { useState } from 'react';
import './App.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import TopicsPanel from './TopicsPanel';  // Make sure the import is correct

function App() {
  const [facts, setFacts] = useState([]);
  const [currentFactIndex, setCurrentFactIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [topic, setTopic] = useState('');

  const fetchFacts = async (topic) => {
    setIsLoading(true);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/facts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ topic }),
    });
    let data = await response.json();
    data = data.map(fact => fact.trim()).filter(fact => fact !== '');
    setFacts(data);
    console.log(data);
    setCurrentFactIndex(0);
    setIsLoading(false);
  };

  return (
    <div className="App">
      <header className="App-header">
        {/* Topics Panel for selecting topics */}
        <TopicsPanel onSelectTopic={(selectedTopic) => {
          setTopic(selectedTopic);  // Update the topic state
          fetchFacts(selectedTopic); // Fetch facts based on selected topic
        }} />
        
        {/* Existing elements */}
        <input
          type="text"
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          placeholder="Enter a topic"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              fetchFacts(e.target.value);
            }
          }}
        />
        <button onClick={() => fetchFacts(topic)}>Generate Facts</button>
        {isLoading && <p>Loading...</p>}

        <div className="swipe-container" style={{ pointerEvents: 'auto' }}>
          {facts.length > 0 && (
            <Carousel
              selectedItem={currentFactIndex}
              onChange={(index) => setCurrentFactIndex(index)}
              swipeable
              emulateTouch
              showThumbs={false}
              showStatus={false}
              showArrows={false}
              useKeyboardArrows
              swipeScrollTolerance={10}
            >
              {facts.map((fact, index) => (
                <div key={index} className="fact-box">
                  <p>{fact}</p>
                </div>
              ))}
            </Carousel>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
